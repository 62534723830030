import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "react-auth-navigation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IoIosCopy } from "react-icons/all";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  BarChart,
  Bar,
  AreaChart,
  Area,
  Cell,
  Label,
} from "recharts";

import { editCCWidget } from "../../../actions";

// const data = [
//   {
//     name: "Jan",
//     Sales: "0",
//   },
//   {
//     name: "Feb",
//     Sales: "3000",
//   },
//   {
//     name: "Mar",
//     Sales: "2000",
//   },
//   {
//     name: "Apr",
//     Sales: "2780",
//   },
//   {
//     name: "May",
//     Sales: 1890,
//   },
//   {
//     name: "Jun",
//     Sales: 800,
//   },
//   {
//     name: "Jul",
//     Sales: 3490,
//   },
//   {
//     name: "Aug",
//     Sales: 2780,
//   },
//   {
//     name: "Sept",
//     Sales: 1890,
//   },
//   {
//     name: "Jun",
//     Sales: 1900,
//   },
//   {
//     name: "Jul",
//     Sales: 3490,
//   },
// ];

// const data01 = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
//   { name: "Group E", value: 278 },
//   { name: "Group F", value: 189 },
// ];

// const data02 = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//   },
// ];

// const data03 = [
//   {
//     name: "Page A",
//     uv: 4000,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

const COLORS = ["#0284a4", "#00C49F", "#FFBB28", "#FF8042"];

const mapStateToProps = (state) => {
  return {
    cc: state.cc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editCCWidget,
    },
    dispatch,
  );
};

export const CustomWidgets = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { toast } = useAuth();
  const textAreaRef = useRef(null);
  const {
    cc,
    topic,
    wg_name,
    chart_type,
    data,
    axis,
    cardId,
    editCCWidget,
  } = props;
  const [widgetTopic, setWidgetTopic] = useState(topic);
  const isTyping = React.useRef(null);

  useEffect(() => {
    setWidgetTopic(topic);
  }, [topic]);

  const handleRenameWidget = (e) => {
    setWidgetTopic(e.target.value);
  };

  useEffect(() => {
    if (isTyping.current) {
      clearTimeout(isTyping.current);
    }
    isTyping.current = setTimeout(function () {
      // no-op when same title
      if (topic !== widgetTopic) {
        editCCWidget({
          cardId,
          topic: widgetTopic,
        });
      }
    }, 500);
  }, [widgetTopic]);

  const chatRenderer = (chart_type, data) => {
    switch (chart_type) {
      case "LINE":
        return <LineChartComp data={data} axis={axis} />;
      case "PIE":
        return <PieChartComp data={data} />;
      case "BAR":
        return <BarChartComp data={data} axis={axis} />;
      default:
        return <AreaChartComp data={data} axis={axis} />;
    }
  };

  const copyHandler = () => {
    textAreaRef.current.select();
    document.execCommand("copy");

    toast({
      message: "ID  copied to clipboard. ",
      type: "success",
    });
  };

  return (
    <div className="google-analytics">
      <input
        className="topic"
        type="text"
        value={widgetTopic}
        onChange={handleRenameWidget}
      />
      {/* <p>{wg_name}</p> */}
      <div className="google-analytics-copy" onClick={() => copyHandler()}>
        <IoIosCopy className="google-analytics-copy-icon" /> Copy ID
        <textarea ref={textAreaRef} value={wg_name} />
      </div>
      {chatRenderer(chart_type, data)}
    </div>
  );
});

const LineChartComp = ({ data, axis }) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={data}
        margin={{
          top: 0,
          right: 20,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="label"
          stroke="#e1e1e1"
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}>
          <Label offset={0} position="middle" dy={6}>
            {axis?.x}
          </Label>
        </XAxis>
        <YAxis
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}
          stroke={{ fill: "rgba(255, 0, 0, 0.0)" }}>
          <Label
            offset={-6}
            angle={270}
            position="left"
            style={{ textAnchor: "middle" }}>
            {axis?.y}
          </Label>
        </YAxis>
        <Line
          type="linear"
          dataKey="value"
          stroke="#0284a4"
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />
        <Tooltip />
        {/* <Legend /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartComp = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          dataKey="value">
          {data?.length &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

const BarChartComp = ({ data, axis }) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          stroke="#e1e1e1"
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}>
          <Label offset={0} position="middle" dy={10}>
            {axis?.x}
          </Label>
        </XAxis>
        <YAxis
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}
          stroke={{ fill: "rgba(255, 0, 0, 0.0)" }}>
          <Label
            offset={-6}
            angle={270}
            position="left"
            style={{ textAnchor: "middle" }}>
            {axis?.y}
          </Label>
        </YAxis>
        <Tooltip />
        <Bar dataKey="value" fill="#0284a4" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const AreaChartComp = ({ data, axis }) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          stroke="#e1e1e1"
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}>
          <Label offset={0} position="middle" dy={6}>
            {axis?.x}
          </Label>
        </XAxis>
        <YAxis
          tick={{ fill: "rgba(202, 208, 230, 1.0)" }}
          stroke={{ fill: "rgba(255, 0, 0, 0.0)" }}>
          <Label
            offset={-6}
            angle={270}
            position="left"
            style={{ textAnchor: "middle" }}>
            {axis?.y}
          </Label>
        </YAxis>
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke="#0284a4" fill="#0284a4" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
