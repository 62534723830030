import React, { useRef } from "react";
import { useNavigation, useAuth } from "react-auth-navigation";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { signupAction } from "../../actions";
import { InputField, DefaultButton, ErrorMessage } from "../common";
import { Card, ActivityIndicator } from "../hocs";

const SignUpPage = (props) => {
  const { signupAction, signUp } = props;
  const { loading } = signUp;
  const { toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const onSignupHandler = (data) => {
    const body = {
      email: data.email,
      password: data.password,
    };
    signupAction(body, gotoLogin, toast);
    console.log("data", data);
  };
  const gotoLogin = () => {
    navigate(routes.Login.path);
  };
  return (
    <div className="login-container">
      <div className="login">
        <Card>
          <form onSubmit={handleSubmit(onSignupHandler)}>
            <div className="login-contents">
              <h1 className="login-head">SIGNUP</h1>
              {/* <div className="login-fields">
              <p className="login-fields-title">Full Name</p>
              <InputField placeholder="Full Name" />
            </div> */}
              <div className="login-fields">
                <p className="login-fields-title">Email</p>
                <InputField
                  placeholder="Email"
                  name="email"
                  // type="email"
                  ref={register({
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please Enter Valid Email",
                    },
                    required: "This is required.",
                  })}
                />
                {errors.email && (
                  <ErrorMessage errorValue={errors.email.message} />
                )}
              </div>
              <div className="login-fields">
                <p className="login-fields-title">Password</p>
                <InputField
                  placeholder="Password"
                  type="password"
                  name="password"
                  ref={register({ required: "This is required." })}
                />
                {errors.password && (
                  <ErrorMessage errorValue={errors.password.message} />
                )}
              </div>

              <div className="login-fields">
                <p className="login-fields-title">Confirm Password</p>
                <InputField
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  ref={register({
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                    required: "This is required.",
                  })}
                />
                {errors.confirmPassword && (
                  <ErrorMessage errorValue={errors.confirmPassword.message} />
                )}
              </div>
              {/* <div className="login-fields">
              <h1 className="login-fields-title">Location</h1>
              <SelectField
                options={list}
                getOptionLabel="name"
                getOptionValue="value"
                onChangeValue={(val) => console.log("selected value", val)}
                isSearchable
                isClearable
                placeholder="Location"
              />
            </div> */}
              <div className="login-action">
                <ActivityIndicator animating={loading}>
                  <DefaultButton
                    title="Signup "
                    classname="fit-content"
                    type="submit"
                  />
                </ActivityIndicator>
              </div>
              <div className="login-info">
                Already registered ? <span onClick={gotoLogin}>Login</span>
              </div>
              <div className="login-footer">
                By clicking below and creating an account, I agree to WifiJob's
                <span> Terms of Service</span> and <span>Privacy Policy</span> .
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    signUp: state.signUp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
