import { createActionSet } from "../helpers";

//SIGNIN ACTION SET
export const SIGNIN = createActionSet("SIGNIN");

//LOGIN ACTION SET
export const LOGIN = createActionSet("LOGIN");
export const LOGOUT = createActionSet("LOGOUT");
export const PASSWORD = createActionSet("PASSWORD");

export const USER = createActionSet("USER");
export const API_KEY = createActionSet("API_KEY");

//NEWS ACTION SET
export const NEWS = createActionSet("NEWS");
export const ADD_NEWS = createActionSet("ADD_NEWS");
export const DELETE_NEWS = createActionSet("DELETE_NEWS");
export const NEWS_DETAIL = createActionSet("NEWS_DETAIL");
export const EDIT_NEWS = createActionSet("EDIT_NEWS");

//SIGNUP ACTION SET
export const SIGNUP = createActionSet("SIGNUP");

// GA
export const GA_AUTH_AUTHORIZED = "GA_AUTH_AUTHORIZED";
export const GA_AUTH_NOT_AUTHORIZED = "GA_AUTH_NOT_AUTHORIZED";

export const ADD_GA_CARD = createActionSet("ADD_GA_CARD");
export const DELETE_GA_CARD = createActionSet("DELETE_GA_CARD");

// editables
export const EDIT_GA_CARD_TOPIC = "EDIT_GA_CARD_TOPIC";
export const EDIT_GA_CARD_METRICS = "EDIT_GA_CARD_METRICS";
export const EDIT_GA_CARD_DIMENSIONS = "EDIT_GA_CARD_DIMENSIONS";

// widgets
export const ADD_SOCIAL_WIDGET = createActionSet("ADD_SOCIAL_WIDGET");
export const ADD_CUSTOM_WIDGET = createActionSet("ADD_CUSTOM_WIDGET");
export const GET_SOCIAL_WIDGET = createActionSet("GET_SOCIAL_WIDGET");
export const EDIT_SOCIAL_WIDGET = createActionSet("EDIT_SOCIAL_WIDGET");
export const DELETE_SOCIAL_WIDGET = createActionSet("DELETE_SOCIAL_WIDGET");

// CUSTOM CHARTS
export const ADD_CC_CARD = createActionSet("ADD_CC_CARD");
export const DELETE_CC_CARD = createActionSet("DELETE_CC_CARD");
export const EDIT_CC_CARD = createActionSet("EDIT_CC_CARD");
export const GET_CC_CARD = createActionSet("GET_CC_CARD");
export const CLEAR_WIDGETS = "CLEAR_WIDGETS";
