import LoginPage from "../loginPage";
import NotFoundPage from "../notFoundPage";
import DashboardPage from "../dashboardPage";
import CustomChartsPage from "../customChartsPage";
import SignUpPage from "../signUpPage";

export const publicPaths = [
  {
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },

  {
    name: "SignUp",
    path: "/signup",
    component: SignUpPage,
    restricted: true,
  },

  {
    path: null,
    component: NotFoundPage,
  },
];

export const privatePaths = [
  {
    name: "Analytics",
    path: "/analytics",
    component: DashboardPage,
  },
  {
    name: "Custom\xa0Charts",
    path: "/custom-charts",
    component: CustomChartsPage,
  },
];
