import { apiGenerator, getCookie } from "../helpers";

// const token = getCookie("token"); // Get token
// export const BASE_URL = "http://139.59.23.105:8854/api"; // BASE URL
export const BASE_URL = "https://api.codniv.com/api"; // BASE URL

export const TABLE_LIMIT = 5;

// API FUNCTION
export const api = apiGenerator({ baseURL: BASE_URL });

// API PATHS
export const APIS = {
  login: "/auth/login",
  logout: "/auth/logout",
  signUp: "auth/register",
  apikey: "auth/token",
  password: "/user/pass",
  common: "/common",
  news: "/common/type?name=news",
  user: "/user",
  widgets: "/widgets",
};
