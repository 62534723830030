/* eslint-disable no-undef */
import { ADD_CC_CARD, DELETE_CC_CARD, GET_CC_CARD, EDIT_CC_CARD } from ".";

import { APIS, api } from "../config";

export function getCCWidget() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_CC_CARD.LOADING });

      let params = `?page=1&limit=10`;
      res = await api(`${APIS.widgets}/custom${params}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: GET_CC_CARD.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_CC_CARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_CC_CARD.ERROR });
      return 0;
    }
  };
}

export function addCCWidget(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_CC_CARD.LOADING });
      res = await api(APIS.widgets, "POST", body);

      const { success, data, message } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_CC_CARD.SUCCESS });

        dispatch(getCCWidget());

        !!toast &&
          toast({
            message: data.message,
            type: "success",
          });
        !!callback && callback();
      } else {
        dispatch({ type: ADD_CC_CARD.ERROR });
        toast({
          message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_CC_CARD.ERROR });
      toast({
        message: `Adding widget failed!`,
        type: "error",
      });
      return 0;
    }
  };
}

export function editCCWidget({
  cardId,
  topic,
  chartType,
  metrics,
  dimensions,
}) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_CC_CARD.LOADING });

      const body = {};
      if (topic) body.wgTitle = topic;
      if (chartType) {
        body.data.type = chartType;
        body.data.metrics = metrics;
        body.data.dimensions = dimensions;
      }

      res = await api(`${APIS.widgets}/${cardId}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EDIT_CC_CARD.SUCCESS });
      } else {
        dispatch({ type: EDIT_CC_CARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_CC_CARD.ERROR });
      return 0;
    }
  };
}

export function deleteCCWidget(cardId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_CC_CARD.LOADING });
      res = await api(`${APIS.widgets}/${cardId}`, "DELETE");

      const { success } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_CC_CARD.SUCCESS });
        dispatch({ type: GET_CC_CARD.CLEAR });
        dispatch(getCCWidget());
      } else {
        dispatch({ type: DELETE_CC_CARD.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_CC_CARD.ERROR });
      return 0;
    }
  };
}
