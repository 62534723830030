import {
  ADD_CC_CARD,
  EDIT_CC_CARD,
  DELETE_CC_CARD,
  GET_CC_CARD,
} from "../actions";

const initalState = {
  // widgets
  addCCWidgetLoader: false,
  getCCWidgetLoader: false,
  editCCWidgetLoader: false,
  deleteCCWidgetLoader: false,
  cCWidgets: null,
};

export function ccReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // widgets
    case GET_CC_CARD.CLEAR:
      return { ...state, getCCWidgetLoader: false, cCWidgets: null };
    case GET_CC_CARD.LOADING:
      return { ...state, getCCWidgetLoader: true, cCWidgets: null };
    case GET_CC_CARD.SUCCESS:
      return { ...state, getCCWidgetLoader: false, cCWidgets: payload };
    case GET_CC_CARD.ERROR:
      return { ...state, getCCWidgetLoader: false };

    case ADD_CC_CARD.LOADING:
      return { ...state, addCCWidgetLoader: true };
    case ADD_CC_CARD.SUCCESS:
      return { ...state, addCCWidgetLoader: false };
    case ADD_CC_CARD.ERROR:
      return { ...state, addCCWidgetLoader: false };

    case EDIT_CC_CARD.LOADING:
      return { ...state, editCCWidgetLoader: true };
    case EDIT_CC_CARD.SUCCESS:
      return { ...state, editCCWidgetLoader: false };
    case EDIT_CC_CARD.ERROR:
      return { ...state, editCCWidgetLoader: false };

    case DELETE_CC_CARD.LOADING:
      return { ...state, deleteCCWidgetLoader: true };
    case DELETE_CC_CARD.SUCCESS:
      return { ...state, deleteCCWidgetLoader: false };
    case DELETE_CC_CARD.ERROR:
      return { ...state, deleteCCWidgetLoader: false };

    default:
      return state;
  }
}
