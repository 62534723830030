import { SIGNUP } from ".";
import { api, APIS } from "../config";

export function signupAction(body, gotoLogin, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: SIGNUP.LOADING });
      res = await api(APIS.signUp, "POST", body);
      console.log("@@@@", res);
      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: SIGNUP.SUCCESS });
        toast({ message: "signup Successful", type: "success" });
        gotoLogin();
      } else {
        toast({ message: data.message, type: "error" });
        dispatch({ type: SIGNUP.ERROR });
      }
    } catch ({ message }) {
      toast({ message: "Signup Failed", type: "error" });
      dispatch({ type: SIGNUP.ERROR });
      console.error(message);
      return 0;
    }
  };
}
