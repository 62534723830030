import React, { useState } from "react";
import {
  AnimatedBlock,
  useMeasure,
  interpolate,
  useAnimatedValue,
} from "react-uicomp";

export function Collapse({ children, expand, timeout = 300 }) {
  const [divheight, setDivheight] = useState(0);
  const dimension = useMeasure(({ height }) => {
    setDivheight(height);
  });

  const open = useAnimatedValue(expand, {
    duration: timeout,
  });

  return (
    <div>
      <AnimatedBlock
        style={{
          height: interpolate(open.value, [0, 1], [0, divheight]),
          overflow: "hidden",
        }}>
        <div style={{ overflow: "hidden" }} {...dimension()}>
          {children}
        </div>
      </AnimatedBlock>
    </div>
  );
}
