import { combineReducers } from "redux";
import { loginReducer } from "./Login.reducer";
import { newsReducer } from "./News.reducer";
import { signupReducer } from "./SignUp.reducer";
import { userReducer } from "./User.reducer";
import { gaReducer } from "./GoogleAnalytics.reducer";
import { ccReducer } from "./CustomCharts.reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  news: newsReducer,
  signUp: signupReducer,
  user: userReducer,
  ga: gaReducer,
  cc: ccReducer,
});

export default rootReducer;
