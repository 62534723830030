import { SIGNUP } from "../actions";

const initalState = {
  loading: false,
  error: false,
};

export function signupReducer(state = initalState, action) {
  const { type } = action;

  switch (type) {
    case SIGNUP.LOADING:
      return { ...state, loading: true, error: false };
    case SIGNUP.SUCCESS:
      return { ...state, loading: false, error: false };
    case SIGNUP.ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
