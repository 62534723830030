import React, { useEffect } from "react";
import {
  Modal,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HiDotsHorizontal, MdAddToPhotos } from "react-icons/all";
import { getCCWidget, deleteCCWidget } from "../../actions";

import { Card, CompWrapper } from "../hocs";
import { CustomWidgets } from "./components/CustomWidgets.component";

const CustomChartsPage = (props) => {
  const { cc, getCCWidget, deleteCCWidget } = props;
  const { cCWidgets } = cc;

  useEffect(() => {
    getCCWidget();
  }, [getCCWidget]);

  const handleDeleteWidget = (cardId) => {
    deleteCCWidget(cardId);
  };

  const handleRefresh = () => {
    getCCWidget();
  };

  return (
    <CompWrapper>
      <div className="dashboard-container">
        <div className="dashboard">
          {cCWidgets ? (
            cCWidgets?.rows.map((widget, index) => {
              return (
                <Card key={index} containerStyle={{ position: "relative" }}>
                  <div className="dashboard-three-dots">
                    <Dropdown
                      placement="bottomright"
                      style={{
                        top: 0,
                      }}
                      triggerElement={() => (
                        <div>
                          <span>
                            <HiDotsHorizontal size={24} />
                          </span>
                        </div>
                      )}>
                      <DropdownMenu>
                        <DropdownMenuItem onClick={() => handleRefresh()}>
                          Refresh
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          danger
                          onClick={() => handleDeleteWidget(widget.id)}>
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <CustomWidgets
                    cardId={widget.id}
                    topic={widget.wg_title}
                    wg_name={widget.wg_name}
                    chart_type={widget.chart_type}
                    data={widget.data?.all}
                    axis={widget.data?.axis}
                  />
                </Card>
              );
            })
          ) : (
            <div>
              No Custom Card Available! You can add from header plus button.
            </div>
          )}
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    cc: state.cc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCCWidget,
      deleteCCWidget,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomChartsPage);
