import React, { useState, useRef } from "react";
import {
  Modal,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "react-uicomp";
import { useAuth } from "react-auth-navigation";

import { useForm } from "react-hook-form";
import {
  MdMenu,
  FaUserCircle,
  MdKeyboardArrowDown,
  MdAddToPhotos,
  IoIosCopy,
} from "react-icons/all";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { passwordAction, getApiKey } from "../../../actions";
import { ActivityIndicator } from "../../hocs";
import { Breadcrumb, InputField, PrimaryButton, SideOptions } from "../index";

const HeaderComponent = (props) => {
  const textAreaRef = useRef(null);
  const { setSideNavVisible, handleLogout, toast } = useAuth();
  const { handleSubmit, register } = useForm();
  const { passwordAction, getApiKey, login, user } = props;
  const { passwordLoader } = login;
  const { apiKey, apiKeyLoader } = user;
  const [visible, setVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);

  const onSubmit = (data) => {
    let body = {
      oldPass: data.oldPassword,
      newPass: data.newPassword,
    };
    if (
      data.newPassword === data.confirmPassword &&
      data.newPassword.length > 7
    ) {
      passwordAction(body, modalCloseHandler, toast);
    } else if (data.newPassword.length < 7) {
      toast({
        message: "Password must be atleaset 8 characters",
        type: "error",
      });
    } else {
      toast({
        message: "Password doesn't match. Please recheck",
        type: "error",
      });
    }
  };

  const modalCloseHandler = () => {
    setVisible(false);
  };

  const apiKeyHandler = () => {
    getApiKey(copyHandler);
  };

  const copyHandler = () => {
    textAreaRef.current.select();
    document.execCommand("copy");

    toast({
      message:
        "API Key copied to clipboard. Please paste this API Key on the Zapier",
      type: "success",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header-left">
          <div className="header-menu" onClick={() => setSideNavVisible(true)}>
            <MdMenu />
          </div>
          <div className="header-breadcrumb">
            <Breadcrumb />
          </div>
        </div>

        <div className="header-api" onClick={() => apiKeyHandler()}>
          <IoIosCopy className="header-api-icon" /> Generate Api Key
          <textarea ref={textAreaRef} value={apiKey?.token} />
        </div>

        <SideOptions
          open={optionsVisible}
          close={() => setOptionsVisible(false)}
        />
        <div className="header-right">
          <div className="header-buttons">
            <button
              className="header-buttons-add"
              onClick={() => {
                setOptionsVisible(!optionsVisible);
              }}>
              <MdAddToPhotos />
            </button>
          </div>

          <div className="logged-user">
            <Dropdown
              placement="bottomright"
              style={{
                top: 0,
              }}
              triggerElement={() => (
                <div className="logged-user-container">
                  <span className="logged-user-icon">
                    <FaUserCircle size={24} />
                  </span>

                  <span className="logged-user-name">
                    {user?.user?.data?.email}
                  </span>

                  <span className="logged-user-arrow-down">
                    <MdKeyboardArrowDown size={24} />
                  </span>
                </div>
              )}>
              <DropdownMenu>
                <DropdownMenuItem
                  className="menuItem"
                  onClick={() => setVisible(true)}>
                  Change Password
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="menuItem danger"
                  style={{ width: "100%", textAlign: "left" }}
                  onClick={() => handleLogout()}>
                  Logout
                </DropdownMenuItem>
              </DropdownMenu>
            </Dropdown>
            <Modal visible={visible} onOutsideClick={() => setVisible(false)}>
              <h3 style={{ marginBottom: 20 }}>Change Password</h3>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  rowGap: 10,
                }}>
                <InputField
                  name="oldPassword"
                  placeholder="Old Password"
                  type="password"
                  innerRef={register({ required: true })}
                />
                <InputField
                  name="newPassword"
                  placeholder="New Password"
                  type="password"
                  innerRef={register({ required: true })}
                />
                <InputField
                  name="confirmPassword"
                  placeholder="Confrim Password"
                  type="password"
                  innerRef={register({ required: true })}
                />
                <ActivityIndicator animating={passwordLoader}>
                  <PrimaryButton title="Submit" type="sumbit" />
                </ActivityIndicator>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      passwordAction,
      getApiKey,
    },
    dispatch,
  );
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderComponent);
