import React from "react";

export const InputField = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    placeholder,
    style,
    onChange,
    className,
    type,
    disabled,
    value,
  } = props;
  return (
    <input
      name={name}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      style={style}
      className={`inputfield ${className}`}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
  );
});

export const TextArea = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    placeholder,
    style,
    onChange,
    className,
    type,
    disabled,
  } = props;
  return (
    <textarea
      name={name}
      ref={ref}
      cols={40}
      rows={5}
      defaultValue={defaultValue}
      onChange={onChange}
      style={style}
      className={`inputfield ${className}`}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
    />
  );
});
