// export const CLIENT_ID =
//   "261157591597-mb5frudjekjntt4pjtng94p21i0keqco.apps.googleusercontent.com";
export const CLIENT_ID =
  "261157591597-oa6q2i78plq2b7p5i417qgub8hegee3t.apps.googleusercontent.com";

export const widgetType = {
  social: {
    GOOGLE: "GOOGLE",
  },
  custom: {
    PIE: "PIE",
    BAR: "BAR",
    LINE: "LINE",
    AREA: "AREA",
  },
};
