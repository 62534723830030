export const LIST = [
  { value: "value1", label: "label1" },
  { value: "value2", label: "label2" },
  { value: "value3", label: "label3" },
  { value: "value4", label: "label4" },
  { value: "value5", label: "label5" },
  { value: "value6", label: "label6" },
];
export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9" },
  { value: "blue", label: "Blue", color: "#0052CC" },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export const flavourOptions = [
  { value: "vanilla", label: "Vanilla", rating: "safe" },
  { value: "chocolate", label: "Chocolate", rating: "good" },
  { value: "strawberry", label: "Strawberry", rating: "wild" },
  { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
];
export const userMetrics = [
  { value: "ga:users", label: "Users" },
  { value: "ga:newUsers", label: "New Users" },
];

export const sessionMetrics = [
  { value: "ga:users", label: "Users" },
  { value: "ga:sessions", label: "Sessions" },
  { value: "ga:bounces", label: "Bounces" },
  { value: "ga:bounceRate", label: "Bounce Rate" },
  { value: "ga:sessionDuration", label: "Session Duration" },
  { value: "ga:averagesessionDuration", label: "Avg. Session Duration" },
  { value: "ga:hits", label: "Hits" },
];

export const groupedMetrics = [
  {
    label: "User",
    options: userMetrics,
  },
  {
    label: "Session",
    options: sessionMetrics,
  },
];

export const userDimension = [
  { value: "ga:userType", label: "User Type" },
  { value: "ga:sessionCount", label: "Count of Session" },
  { value: "ga:daysSinceLastSession", label: "Days Since Last Session" },
  { value: "ga:userDefinedValue", label: "User Defined Value" },
];

export const trafficDimension = [
  { value: "ga:userType", label: "User Type" },
  { value: "ga:fullReferrer", label: "Referral Path" },
  { value: "ga:campaign", label: "Campaign" },
  { value: "ga:source", label: "Source" },
  { value: "ga:medium", label: "Medium" },
  { value: "ga:sourceMedium", label: "Source/Medium" },
  { value: "ga:keyword", label: "Keyword" },
  { value: "ga:adContent", label: "Ad Content" },
  { value: "ga:socialNetwork", label: "Social Network" },
  { value: "ga:hasSocialSourceReferral", label: "Social Network Referral" },
  { value: "ga:campaignCode", label: "Campaign Code" },
];

export const networkDimension = [
  { value: "ga:country", label: "Country" },
  { value: "ga:region", label: "Region" },
  { value: "ga:metro", label: "Metro" },
  { value: "ga:city", label: "City" },
];

export const timeDimension = [{ value: "ga:date", label: "Time" }];

export const groupedDimension = [
  {
    label: "User",
    options: userDimension,
  },
  {
    label: "Traffic Sources",
    options: trafficDimension,
  },

  {
    label: "Geo Network",
    options: networkDimension,
  },
  {
    label: "Time",
    options: timeDimension,
  },
];

export const groupedOptions = [
  {
    label: "Colours",
    options: colourOptions,
  },
  {
    label: "Flavours",
    options: flavourOptions,
  },
];
