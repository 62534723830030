/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { interpolate, AnimatedBlock, useAnimatedValue } from "react-uicomp";
import { useNavigation } from "react-auth-navigation";
import { useAuth } from "react-auth-navigation";
import { HiOutlineChevronDown, GrClose } from "react-icons/all";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  gaAuthSuccess,
  gaAuthFailure,
  gaLogin,
  addGaCard,
  addSocialWidget,
  addCCWidget,
} from "../../../actions";

import { Card, Collapse } from "../../hocs";
import { PrimaryButton } from "../";
import { CLIENT_ID, widgetType } from "../../../constants";

const mapStateToProps = (state) => {
  return {
    ga: state.ga,
    cc: state.cc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      gaLogin,
      gaAuthSuccess,
      gaAuthFailure,
      addGaCard,
      addSocialWidget,
      addCCWidget,
    },
    dispatch,
  );
};

export const SideOptions = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const {
    ga,
    cc,
    gaLogin,
    gaAuthFailure,
    addGaCard,
    addSocialWidget,
    addCCWidget,
  } = props;
  const { gaCards, isAuthorized } = ga;
  const { toast } = useAuth();
  const { location } = useNavigation();

  const [modalVisisble, setModalVisible] = useState(true);
  const [reportModalVisisble, setReportModalVisible] = useState(true);
  const [customChartVisible, setCustomChartVisible] = useState(true);
  const open = useAnimatedValue(props.open);

  useEffect(() => {
    gaLogin("auth-button", CLIENT_ID);
  }, [gaLogin]);

  useEffect(() => {
    if (location.pathname === "/custom-charts") {
      setModalVisible(false);
      setReportModalVisible(false);
      setCustomChartVisible(true);
    } else {
      setModalVisible(true);
      setReportModalVisible(true);
      setCustomChartVisible(false);
    }
  }, [location]);

  useEffect(() => {
    document.getElementById("auth-button").style.display = isAuthorized
      ? "none"
      : "block";
    document.getElementById("logout-button").style.display = isAuthorized
      ? "block"
      : "none";
  }, [isAuthorized]);

  const handleAddGaCard = () => {
    addGaCard({
      cardId: gaCards.length + 1,
      topic: "default topic",
      metrics: "ga:sessions",
      dimensions: "ga:userType",
    });
  };

  const handleAddSocialWidget = (wgType) => {
    addSocialWidget(
      {
        wgTitle: "Total new users",
        chartType: "NONE",
        wgType,
        data: {
          type: "BAR",
          dimensions: "ga:userType",
          metrics: "ga:newUsers",
        },
      },
      toast,
      function () {
        console.log("ADDED");
      },
    );
  };

  const handleAddCCWidget = (wgType) => {
    addCCWidget(
      {
        wgTitle: `${wgType} Chart`,
        chartType: wgType,
        wgType: "CUSTOM",
      },
      toast,
      function () {
        console.log("ADDED");
      },
    );
  };

  return (
    <>
      <AnimatedBlock
        style={{
          right: interpolate(open.value, [0, 1], [-400, 0]),
        }}
        className="sideoptions-wrapper">
        <Card containerStyle={{ height: "100%" }}>
          <div className="container">
            <div onClick={props.close} className="closeIcon">
              <GrClose size={25} />
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => setModalVisible(!modalVisisble)}>
              <div>Social Media</div>
              <div
                style={{
                  transform: `${
                    modalVisisble ? `rotate(180deg)` : `rotate(0deg)`
                  }`,
                  transition: "transform 300ms",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <HiOutlineChevronDown size={20} />
              </div>
            </div>
            <Collapse expand={modalVisisble}>
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  Google
                  <section
                    id="auth-button"
                    style={{ cursor: "pointer" }}></section>
                  {}
                  <PrimaryButton
                    id="logout-button"
                    title="Log Out"
                    onClick={() => {
                      gapi.analytics.auth.signOut();
                      gaAuthFailure();
                    }}
                  />
                </div>
              </Card>
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  Weebly
                  <section
                    id="auth-button"
                    style={{ cursor: "pointer" }}></section>
                  {}
                  <PrimaryButton title="Coming soon" disabled />
                </div>
              </Card>
            </Collapse>

            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => setReportModalVisible(!reportModalVisisble)}>
              <div>Reporting</div>
              <div
                style={{
                  transform: `${
                    reportModalVisisble ? `rotate(180deg)` : `rotate(0deg)`
                  }`,
                  transition: "transform 300ms",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <HiOutlineChevronDown size={20} />
              </div>
            </div>
            <Collapse expand={reportModalVisisble}>
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  Google Analytics
                  <PrimaryButton
                    title="Add"
                    onClick={() =>
                      handleAddSocialWidget(widgetType.social.GOOGLE)
                    }
                    disabled={isAuthorized ? false : true}
                  />
                </div>
              </Card>
            </Collapse>

            <div>
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
                onClick={() => setCustomChartVisible(!customChartVisible)}>
                <div>Custom Charts</div>
                <div
                  style={{
                    transform: `${
                      customChartVisible ? `rotate(180deg)` : `rotate(0deg)`
                    }`,
                    transition: "transform 300ms",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <HiOutlineChevronDown size={20} />
                </div>
              </div>
              <Collapse expand={customChartVisible}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    Line Charts
                    <PrimaryButton
                      title="Add"
                      onClick={() => handleAddCCWidget(widgetType.custom.LINE)}
                    />
                  </div>
                </Card>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    Pie Charts
                    <PrimaryButton
                      title="Add"
                      onClick={() => handleAddCCWidget(widgetType.custom.PIE)}
                    />
                  </div>
                </Card>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    Bar Diagram
                    <PrimaryButton
                      title="Add"
                      onClick={() => handleAddCCWidget(widgetType.custom.BAR)}
                    />
                  </div>
                </Card>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    Area Chart
                    <PrimaryButton
                      title="Add"
                      onClick={() => handleAddCCWidget(widgetType.custom.AREA)}
                    />
                  </div>
                </Card>
              </Collapse>
            </div>
          </div>
        </Card>
      </AnimatedBlock>
    </>
  );
});
