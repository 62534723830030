import React from "react";
import { BottomSheet } from "../../hocs";
import { useAuth } from "react-auth-navigation";
import { useDispatch } from "react-redux";

import { editSocialWidget } from "../../../actions";

import {
  PrimaryButton,
  SelectField,
  groupedDimension,
  groupedMetrics,
} from "../";

const CHARTS = [
  { value: "PIE", label: "Pie Chart" },
  { value: "BAR", label: "Bar Chart" },
  { value: "LINE", label: "Line Chart" },
  { value: "GEO", label: "Geo Chart" },
  { value: "COLUMN", label: "Column Chart" },
  { value: "TABLE", label: "Table Chart" },
];

export const ModalComponent = (props) => {
  const {
    open,
    closeComponent,

    // ga fields
    cardId,
    gaFields,
    gaFieldsDispatch,
  } = props;

  const { toast } = useAuth();
  const dispatch = useDispatch();
  const { chart, metrics, dimensions } = gaFields;

  const formatGroupLabel = (data) => (
    <div className="group">
      <span>{data.label}</span>
    </div>
  );

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      editSocialWidget({
        cardId,
        topic: null,
        chartType: chart,
        metrics: metrics,
        dimensions: dimensions,
      }),
    );

    closeComponent(false);
  };

  const selectedChart = Object.create(
    CHARTS.find((val) => val.value === chart),
  );

  const getDefaultValue = (group, defaultValue) => {
    let newArray = [];

    group.forEach((element) => {
      const data = element.options.find(
        (element) => element.value === defaultValue,
      );
      if (!!data) return newArray.push(data);
    });
    return newArray[0];
  };

  return (
    <div>
      <BottomSheet open={open} onClose={() => closeComponent(false)}>
        <div style={{ height: "90vh" }} className="modal">
          <div className="modal-input-container">
            <p>Metrics</p>
            <SelectField
              instanceId="metrics"
              options={groupedMetrics}
              getOptionLabel="label"
              getOptionValue="value"
              placeholder="Select Metrics"
              formatGroupLabel={formatGroupLabel}
              onChangeValue={({ value }) => false}
              defaultValue={getDefaultValue(groupedMetrics, metrics)}
            />
          </div>

          <div className="modal-input-container">
            <p>Dimension</p>
            <SelectField
              instanceId="dimension"
              options={groupedDimension}
              getOptionLabel="label"
              getOptionValue="value"
              placeholder="Select Dimension"
              formatGroupLabel={formatGroupLabel}
              onChangeValue={({ value }) => false}
              defaultValue={getDefaultValue(groupedDimension, dimensions)}
            />
          </div>
          <div className="modal-input-container">
            <p>Chart Type</p>
            <SelectField
              instanceId="chart"
              options={CHARTS}
              getOptionLabel="label"
              getOptionValue="value"
              placeholder="Select Chart Type"
              formatGroupLabel={formatGroupLabel}
              onChangeValue={({ value }) => {
                gaFieldsDispatch({ type: "CHART", payload: value });
              }}
              defaultValue={selectedChart}
            />
          </div>

          <div className="modal-input-container">
            <PrimaryButton title="Submit" type="submit" onClick={onSubmit} />
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};
