export const userType = { ADMIN: "admin", USER: "user" };

export const userRoles = {
  [userType.ADMIN]: {
    access: ["*"],
  },
  [userType.USER]: {
    access: ["/", "/log-in", "/signup"],
  },
};
