import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { useAuth, useNavigation } from "react-auth-navigation";

import { loginAction } from "../../actions";
import { InputField, DefaultButton, ErrorMessage } from "../common";
import { Card, ActivityIndicator } from "../hocs";

const LoginPage = (props) => {
  const { handleLogin, toast } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { register, handleSubmit, errors } = useForm();
  const { loginAction, login } = props;
  const { loading } = login;

  const onLogin = async (data) => {
    let body = {
      email: data.email,
      password: data.password,
    };
    loginAction(body, handleLogin, toast);
  };

  return (
    <div className="login-container">
      <div className="login">
        <Card>
          <form onSubmit={handleSubmit(onLogin)}>
            <div className="login-contents">
              <h1 className="login-head">LOGIN</h1>
              <div className="login-fields">
                <p className="login-fields-title">Email</p>
                <InputField
                  placeholder="Email"
                  name="email"
                  ref={register({
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please Enter Valid Email",
                    },
                    required: "This is required.",
                  })}
                />
                {errors.email && (
                  <ErrorMessage errorValue={errors.email.message} />
                )}
              </div>
              <div className="login-fields">
                <h1 className="login-fields-title">Password</h1>
                <InputField
                  placeholder="Password"
                  name="password"
                  ref={register({ required: "This is required." })}
                  type="password"
                />
                {errors.password && (
                  <ErrorMessage errorValue={errors.password.message} />
                )}
              </div>
              {/* <div className="login-forgot">Forgot Password ?</div> */}

              <div className="login-action">
                <ActivityIndicator animating={loading}>
                  <DefaultButton
                    title="Login"
                    classname="fit-content"
                    type="submit"
                  />
                </ActivityIndicator>
              </div>
              <div className="login-info">
                Not registered ?{" "}
                <span
                  onClick={() => {
                    navigate(routes.SignUp.path);
                  }}>
                  Signup
                </span>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
