import { USER, API_KEY } from ".";
import { api, APIS } from "../config";
import { getCookie } from "../helpers";

export function userAuthAction(loading, loginSuccess, loginFailure) {
  return async function (dispatch) {
    let res;
    try {
      if (!getCookie("token")) {
        return loading(false);
      }

      dispatch({ type: USER.LOADING });
      res = await api(APIS.user);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: USER.SUCCESS, payload: data });

        if (data.type === "success") {
          loginSuccess();
        } else {
          loginFailure();
        }

        loading(false);
      } else {
        dispatch({ type: USER.ERROR });
        loading(false);
      }
    } catch ({ message }) {
      dispatch({ type: USER.ERROR });
      loading(false);
      return 0;
    }
  };
}

export function userAuthLogoutAction(callback) {
  return async function () {
    let res;
    try {
      res = await api(APIS.logout);

      const { success } = res.data;

      if (success === "true") {
        callback();
      }
    } catch ({ message }) {
      return 0;
    }
  };
}

export function getApiKey(copyHandler) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: API_KEY.LOADING });
      res = await api(`${APIS.apikey}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: API_KEY.SUCCESS, payload: data.data });
        dispatch(copyHandler());
      } else {
        dispatch({ type: API_KEY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: API_KEY.ERROR });
      console.error(message);
      return 0;
    }
  };
}
