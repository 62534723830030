import React from "react";
import Select from "react-select";

const selectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 4,
    borderColor: isFocused ? "#0284a4" : "#E1E1E1",
    backgroundColor: "#F8F8F8",
    boxShadow: isFocused && "none",
    "&:hover": { borderColor: "#0284a4" },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#0284a4" : "#fff",
      "&:hover": { backgroundColor: "#e9e9e9" },
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
};

export const SelectField = ({
  options,
  formatGroupLabel,
  onChangeValue,
  getOptionLabel,
  getOptionValue,
  isSearchable,
  isClearable,
  placeholder,
  isLoading,
  instanceId,
  defaultValue,
}) => {
  const changehandler = (data) => {
    onChangeValue(data);
  };
  return (
    <div>
      <Select
        instanceId={instanceId}
        className="selectfield"
        classNamePrefix="react-select"
        styles={selectStyles}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        onChange={changehandler}
        getOptionLabel={(option) => `${option[getOptionLabel]}`}
        getOptionValue={(option) => `${option[getOptionValue]}`}
        options={options}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  );
};
