import React, { useEffect, useState, useRef } from "react";
import { MdClose } from "react-icons/md";
import {
  useAnimatedValue,
  AnimatedBlock,
  interpolate,
  useOutsideClick,
} from "react-uicomp";

export const BottomSheet = ({ open, onClose, children }) => {
  const modalRef = useRef();
  const openAnimation = useAnimatedValue(open, {
    animationType: "ease",
    duration: 500,
  });
  const bottom = useAnimatedValue(0, { animationType: "ease" });
  const height = useAnimatedValue(0, { animationType: "ease" });

  useOutsideClick(modalRef, () => onClose(), []);

  useEffect(() => {
    height.value = open ? 90 : 0;
    bottom.value = open ? 0 : -100;
  }, [open]);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
  }, [open]);
  return (
    <AnimatedBlock
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        height: open ? "100%" : 0,
        opacity: interpolate(openAnimation.value, [0, 1], [0.8, 1]),
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 50,
      }}>
      <AnimatedBlock
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          cursor: "pointer",
          color: "white",
          opacity: interpolate(openAnimation.value, [0, 1], [0, 1]),
        }}
        onClick={onClose}>
        <MdClose size={30} />
      </AnimatedBlock>
      <AnimatedBlock
        ref={modalRef}
        style={{
          position: "fixed",
          bottom: bottom.value,
          left: 0,
          height: interpolate(height.value, [0, 90], ["0vh", "90vh"]),
          width: "100%",
          backgroundColor: "white",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          padding: 20,
        }}>
        <div style={{ overflowY: "auto", maxHeight: "90vh" }}>{children}</div>
      </AnimatedBlock>
    </AnimatedBlock>
  );
};
