import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  gaAuthSuccess,
  gaAuthFailure,
  gaLogin,
  gaFetch,
  editGaCard,
  gaMetaDataFetch,
  addSocialWidget,
  editSocialWidget,
  clearSocialWidgets,
} from "../../../actions";

import { HiOutlineChevronDown, BsArrowRight } from "react-icons/all";
import { ModalComponent, PrimaryButton } from "../../common";
import { Collapse } from "../../hocs";

const mapStateToProps = (state) => {
  return {
    ga: state.ga,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      gaLogin,
      gaAuthSuccess,
      gaAuthFailure,
      gaFetch,
      editGaCard,
      gaMetaDataFetch,
      addSocialWidget,
      editSocialWidget,
      clearSocialWidgets,
    },
    dispatch,
  );
};

function gaFieldsReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "METRICS":
      return { ...state, metrics: payload };
    case "DIMENSIONS":
      return { ...state, dimensions: payload };
    case "CHART":
      return { ...state, chart: payload };
    default:
      return state;
  }
}

export const GoogleAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const {
    ga,
    cardId,
    topic,
    metrics,
    dimensions,
    chartType,
    gaFetch,
    editSocialWidget,
    clearSocialWidgets,
  } = props;
  const { isAuthorized } = ga;
  const [showConfigure, setShowConfigure] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const isTyping = React.useRef(null);
  const [widgetTopic, setWidgetTopic] = useState(topic);
  const [gaFields, gaFieldsDispatch] = useReducer(gaFieldsReducer, {
    metrics,
    dimensions,
    chart: chartType,
  });

  useEffect(() => {
    if (isAuthorized) {
      const { metrics, dimensions, chart } = gaFields;

      gaFetch({ cardId, dimensions, metrics, chart });
    }
  }, [gaFields, cardId, isAuthorized]);

  useEffect(() => {
    document.getElementById("timeline_" + cardId).style.display = isAuthorized
      ? "block"
      : "none";
    document.getElementById(
      "view-selector_" + cardId,
    ).style.display = isAuthorized ? "block" : "none";
  }, [isAuthorized, cardId, isAuthorized]);

  const handleRenameWidget = (e) => {
    setWidgetTopic(e.target.value);
  };

  useEffect(() => {
    if (isTyping.current) {
      clearTimeout(isTyping.current);
    }
    isTyping.current = setTimeout(function () {
      // no-op when same title
      if (topic !== widgetTopic) {
        editSocialWidget({
          cardId,
          topic: widgetTopic,
          chartType: null,
          metrics: null,
          dimensions: null,
        });
      }
    }, 500);
  }, [widgetTopic]);

  return (
    <div className="google-analytics">
      <input
        className="topic"
        type="text"
        value={widgetTopic}
        onChange={handleRenameWidget}
      />
      <div
        className="collapse-title"
        onClick={() => setShowConfigure(!showConfigure)}>
        <h4>configure</h4>
        <div
          style={{
            transform: `${showConfigure ? `rotate(180deg)` : `rotate(0deg)`}`,
            transition: "transform 300ms",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <HiOutlineChevronDown size={20} />
        </div>
      </div>
      <Collapse expand={showConfigure}>
        <div className="collapsebody">
          <section
            className="select"
            id={"ga-auth-button_" + cardId}
            style={{ display: "none" }}></section>
          <section
            className="select"
            id={"view-selector_" + cardId}
            style={{ display: "none" }}></section>
        </div>
      </Collapse>
      <section
        className="select timeline"
        id={"timeline_" + cardId}
        style={{ display: "none" }}></section>
      <div>
        {ga.isAuthorized && (
          <PrimaryButton
            title="configure"
            righticon={<BsArrowRight />}
            onClick={() => setIsVisible(true)}
          />
        )}
      </div>
      <ModalComponent
        open={isVisible}
        closeComponent={setIsVisible}
        {...{
          cardId,
          gaFields,
          gaFieldsDispatch,
          editSocialWidget,
        }}
      />
    </div>
  );
});
