import React, { useEffect } from "react";
import {
  Modal,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HiDotsHorizontal } from "react-icons/all";
import {
  addGaCard,
  deleteGaCard,
  getSocialWidgets,
  deleteSocialWidget,
} from "../../actions";

import { GoogleAnalytics } from "./components";
import { Card, CompWrapper } from "../hocs";

const DashboardPage = (props) => {
  const { ga, deleteGaCard, getSocialWidgets, deleteSocialWidget } = props;
  const { gaCards, socialWidgets, isAuthorized, getSocialWidgetLoader } = ga;

  useEffect(() => {
    if (isAuthorized) {
      getSocialWidgets();
    }
  }, [getSocialWidgets, isAuthorized]);

  const handleDeleteWidget = (cardId) => {
    deleteSocialWidget(cardId);
  };

  return (
    <CompWrapper>
      <div className="dashboard-container">
        <div className="dashboard">
          {getSocialWidgetLoader ? (
            <div>Loading...</div>
          ) : socialWidgets && isAuthorized ? (
            socialWidgets?.rows.map((widget, index) => {
              return (
                <Card key={index} containerStyle={{ position: "relative" }}>
                  <div className="dashboard-three-dots">
                    <Dropdown
                      placement="bottomright"
                      style={{
                        top: 0,
                      }}
                      triggerElement={() => (
                        <div>
                          <span>
                            <HiDotsHorizontal size={24} />
                          </span>
                        </div>
                      )}>
                      <DropdownMenu>
                        <DropdownMenuItem
                          onClick={() => handleDeleteWidget(widget.id)}>
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <GoogleAnalytics
                    cardId={widget.id}
                    topic={widget.wg_title}
                    metrics={widget.data.metrics}
                    dimensions={widget.data.dimensions}
                    chartType={widget.data.type}
                  />
                </Card>
              );
            })
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ga: state.ga,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addGaCard,
      deleteGaCard,
      getSocialWidgets,
      deleteSocialWidget,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
