/* eslint-disable no-undef */
import {
  GA_AUTH_AUTHORIZED,
  GA_AUTH_NOT_AUTHORIZED,
  ADD_GA_CARD,
  DELETE_GA_CARD,
  EDIT_GA_CARD_TOPIC,
  EDIT_GA_CARD_METRICS,
  EDIT_GA_CARD_DIMENSIONS,

  // widgets
  ADD_SOCIAL_WIDGET,
  ADD_CUSTOM_WIDGET,
} from ".";

import { APIS, api } from "../config";
import {
  DELETE_SOCIAL_WIDGET,
  EDIT_SOCIAL_WIDGET,
  GET_SOCIAL_WIDGET,
} from "./ActionTypes.action";

// ga login
export function gaLogin(elem, CLIENT_ID) {
  return async function (dispatch) {
    gapi.analytics.ready(function () {
      gapi.analytics.auth.authorize({
        container: elem,
        clientid: CLIENT_ID,
      });

      gapi.analytics.auth.on("success", function () {
        dispatch(gaAuthSuccess());
      });
    });
  };
}

// ga fetch
export function gaFetch({ metrics, dimensions, cardId, chart }) {
  return async function () {
    gapi.analytics.ready(function () {
      var viewSelector = new gapi.analytics.ext.ViewSelector2({
        container: "view-selector_" + cardId,
      });

      var timeline = new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          dimensions,
          metrics,
          "start-date": "30daysAgo",
          "end-date": "yesterday",
        },
        chart: {
          type: chart,
          container: "timeline_" + cardId,
        },
      });

      viewSelector.execute();

      viewSelector.on("change", function (ids) {
        var newIds = {
          query: {
            ids: ids,
          },
        };
        timeline.set(newIds).execute();
      });
    });
  };
}

// ga client meta data
export function gaMetaDataFetch() {
  return async function (dispatch) {
    gapi.client.load("analytics", "v3", function () {
      var request = gapi.client.analytics.metadata.columns.list({
        reportType: "ga",
      });

      request.execute(function (results) {
        const columns = results.items;

        // COLUMNS HAS ALL DATA
      });
    });
  };
}

// success
export function gaAuthSuccess() {
  return async function (dispatch) {
    dispatch({ type: GA_AUTH_AUTHORIZED });
  };
}

// failure
export function gaAuthFailure() {
  return async function (dispatch) {
    dispatch({ type: GA_AUTH_NOT_AUTHORIZED });
  };
}

// add ga card
export function addGaCard({ cardId, topic, metrics, dimensions }) {
  return async function (dispatch) {
    dispatch({ type: ADD_GA_CARD.LOADING });

    dispatch({
      type: ADD_GA_CARD.SUCCESS,
      payload: { cardData: { cardId, topic, metrics, dimensions } },
    });

    dispatch(gaFetch({ cardId, topic, metrics, dimensions }));
  };
}

// delete ga card
export function deleteGaCard(cardId) {
  return async function (dispatch) {
    dispatch({ type: DELETE_GA_CARD.LOADING });

    dispatch({
      type: DELETE_GA_CARD.SUCCESS,
      payload: { cardId },
    });
  };
}

// edit ga card
export function editGaCard({ cardId, topic, metrics, dimensions }) {
  return async function (dispatch) {
    if (topic !== undefined) {
      dispatch({ type: EDIT_GA_CARD_TOPIC, payload: { cardId, topic } });
    } else if (metrics !== undefined || dimensions !== undefined) {
      dispatch({ type: EDIT_GA_CARD_METRICS, payload: { cardId, metrics } });
    } else if (dimensions !== undefined) {
      dispatch({
        type: EDIT_GA_CARD_DIMENSIONS,
        payload: { cardId, dimensions },
      });
    }
  };
}

// widgets
export function getSocialWidgets() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_SOCIAL_WIDGET.LOADING });

      let params = `?page=1&limit=10`;
      res = await api(`${APIS.widgets}/social${params}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: GET_SOCIAL_WIDGET.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_SOCIAL_WIDGET.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_SOCIAL_WIDGET.ERROR });
      return 0;
    }
  };
}

export function addSocialWidget(body, toast, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_SOCIAL_WIDGET.LOADING });
      res = await api(APIS.widgets, "POST", body);

      const { success, data, message } = res.data;

      if (success === "true") {
        dispatch({ type: ADD_SOCIAL_WIDGET.SUCCESS });

        dispatch(getSocialWidgets());

        !!toast &&
          toast({
            message: data.message,
            type: "success",
          });
        !!callback && callback();
      } else {
        dispatch({ type: ADD_SOCIAL_WIDGET.ERROR });
        toast({
          message,
          type: "error",
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_SOCIAL_WIDGET.ERROR });
      toast({
        message: `Adding widget failed!`,
        type: "error",
      });
      return 0;
    }
  };
}

const isDefined = (value) => {
  return value !== undefined && value !== null;
};

export function editSocialWidget({
  cardId,
  topic,
  chartType,
  metrics,
  dimensions,
}) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_SOCIAL_WIDGET.LOADING });

      let body = {};
      if (isDefined(topic)) body.wgTitle = topic;
      if (isDefined(chartType)) {
        const data = {
          type: chartType,
          metrics,
          dimensions,
        };

        body.data = data;
      }

      res = await api(`${APIS.widgets}/${cardId}`, "PATCH", body);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EDIT_SOCIAL_WIDGET.SUCCESS });
      } else {
        dispatch({ type: EDIT_SOCIAL_WIDGET.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_SOCIAL_WIDGET.ERROR });
      return 0;
    }
  };
}

export function deleteSocialWidget(cardId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_SOCIAL_WIDGET.LOADING });
      res = await api(`${APIS.widgets}/${cardId}`, "DELETE");

      const { success } = res.data;

      if (success === "true") {
        dispatch({ type: DELETE_SOCIAL_WIDGET.SUCCESS });
        dispatch({ type: GET_SOCIAL_WIDGET.CLEAR });
        dispatch(getSocialWidgets());
      } else {
        dispatch({ type: DELETE_SOCIAL_WIDGET.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_SOCIAL_WIDGET.ERROR });
      return 0;
    }
  };
}

export function clearSocialWidgets() {
  return async function (dispatch) {
    dispatch({ type: GET_SOCIAL_WIDGET.CLEAR });
  };
}
