import { USER, API_KEY } from "../actions";

const initalState = {
  loading: false,
  user: null,
  apiKeyLoader: false,
  apiKey: null,
};

export function userReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER.LOADING:
      return { ...state, loading: true, user: null };
    case USER.SUCCESS:
      return { ...state, loading: false, user: payload };
    case USER.ERROR:
      return { ...state, loading: false, user: null };

    case API_KEY.LOADING:
      return { ...state, apiKeyLoader: true, apiKey: null };
    case API_KEY.SUCCESS:
      return { ...state, apiKeyLoader: false, apiKey: payload };
    case API_KEY.ERROR:
      return { ...state, apiKeyLoader: false, apiKey: null };

    default:
      return state;
  }
}
