import React from "react";

export const DefaultButton = React.forwardRef((props, ref) => {
  const { title, lefticon, righticon, classname, ...rest } = props;
  return (
    <button ref={ref} className={`button default ${classname}`} {...rest}>
      {lefticon}
      {title}
      {righticon}
    </button>
  );
});

export const PrimaryButton = (props) => {
  const { title, lefticon, righticon, classname, ...rest } = props;
  return (
    <button className={`button primary ${classname}`} {...rest}>
      {lefticon}
      {title}
      {righticon}
    </button>
  );
};

export const GhostButton = (props) => {
  const { title, lefticon, righticon, classname, ...rest } = props;
  return (
    <button className={`button ghost ${classname}`} {...rest}>
      {lefticon}
      {title}
      {righticon}
    </button>
  );
};
