import {
  GA_AUTH_AUTHORIZED,
  GA_AUTH_NOT_AUTHORIZED,
  ADD_GA_CARD,
  DELETE_GA_CARD,
  EDIT_GA_CARD_TOPIC,
  EDIT_GA_CARD_METRICS,
  EDIT_GA_CARD_DIMENSIONS,

  // widgets
  ADD_SOCIAL_WIDGET,
  ADD_CUSTOM_WIDGET,
  GET_SOCIAL_WIDGET,
  EDIT_SOCIAL_WIDGET,
  DELETE_SOCIAL_WIDGET,
} from "../actions";

const initalState = {
  isAuthorized: false,

  // ga cards
  gaCards: [],
  gaCardsLoading: false,

  // widgets
  addSocialWidgetLoader: false,
  getSocialWidgetLoader: false,
  editSocialWidgetLoader: false,
  deleteSocialWidgetLoader: false,
  socialWidgets: null,
};

const editHandler = ({ array, payload, key }) => {
  const gaCards = [...array];
  const gaCardObject = gaCards.find((obj) => obj.cardId === payload.cardId);
  const index = gaCards.indexOf(gaCardObject);
  const gaCardObjClone = { ...gaCardObject };
  gaCardObjClone[key] = payload[key];
  gaCards[index] = gaCardObjClone;

  return gaCards;
};

export function gaReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GA_AUTH_AUTHORIZED:
      return { ...state, isAuthorized: true };

    case GA_AUTH_NOT_AUTHORIZED:
      return { ...state, isAuthorized: false };

    // ga cards
    case ADD_GA_CARD.LOADING:
      return { ...state, gaCardsLoading: true };
    case ADD_GA_CARD.SUCCESS:
      return {
        ...state,
        gaCards: [...state.gaCards, payload.cardData],
        gaCardsLoading: false,
      };
    case ADD_GA_CARD.ERROR:
      return { ...state, gaCardsLoading: false };

    case DELETE_GA_CARD.LOADING:
      return { ...state, gaCardsLoading: true };
    case DELETE_GA_CARD.SUCCESS:
      return {
        ...state,
        gaCards: state.gaCards.filter((card) => card.cardId !== payload.cardId),
        gaCardsLoading: false,
      };
    case DELETE_GA_CARD.ERROR:
      return { ...state, gaCardsLoading: false };

    case EDIT_GA_CARD_TOPIC:
      return {
        ...state,
        gaCards: editHandler({
          array: state.gaCards,
          payload,
          key: "topic",
        }),
      };

    case EDIT_GA_CARD_METRICS:
      return {
        ...state,
        gaCards: editHandler({
          array: state.gaCards,
          payload,
          key: "metrics",
        }),
      };

    case EDIT_GA_CARD_DIMENSIONS:
      return {
        ...state,
        gaCards: editHandler({
          array: state.gaCards,
          payload,
          key: "dimensions",
        }),
      };

    // widgets
    case GET_SOCIAL_WIDGET.CLEAR:
      return { ...state, getSocialWidgetLoader: false, socialWidgets: null };
    case GET_SOCIAL_WIDGET.LOADING:
      return { ...state, getSocialWidgetLoader: true };
    case GET_SOCIAL_WIDGET.SUCCESS:
      return { ...state, getSocialWidgetLoader: false, socialWidgets: payload };
    case GET_SOCIAL_WIDGET.ERROR:
      return { ...state, getSocialWidgetLoader: false };

    case ADD_SOCIAL_WIDGET.LOADING:
      return { ...state, addSocialWidgetLoader: true };
    case ADD_SOCIAL_WIDGET.SUCCESS:
      return { ...state, addSocialWidgetLoader: false };
    case ADD_SOCIAL_WIDGET.ERROR:
      return { ...state, addSocialWidgetLoader: false };

    case EDIT_SOCIAL_WIDGET.LOADING:
      return { ...state, editSocialWidgetLoader: true };
    case EDIT_SOCIAL_WIDGET.SUCCESS:
      return { ...state, editSocialWidgetLoader: false };
    case EDIT_SOCIAL_WIDGET.ERROR:
      return { ...state, editSocialWidgetLoader: false };

    case DELETE_SOCIAL_WIDGET.LOADING:
      return { ...state, deleteSocialWidgetLoader: true };
    case DELETE_SOCIAL_WIDGET.SUCCESS:
      return { ...state, deleteSocialWidgetLoader: false };
    case DELETE_SOCIAL_WIDGET.ERROR:
      return { ...state, deleteSocialWidgetLoader: false };

    default:
      return state;
  }
}
